.nav-link {
  color: #3c4a5b;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  gap: 4px;
  align-items: center;

  @media (max-width: 1035px) {
    font-size: 0.9rem; /* Tamaño de texto para pantallas grandes */
  }

  @media (max-width: 988px) {
    font-size: 0.7rem;
    gap: 1px; /* Tamaño de texto para pantallas grandes */
  }
  @media (max-width: 840px) {
    font-size: 0.6rem;
    gap: 1px; /* Tamaño de texto para pantallas grandes */
  }
  @media (max-width: 800px) {
    font-size: 0.5rem;
    gap: 1px; /* Tamaño de texto para pantallas grandes */
  }
}

.selected-link {
  color: #3c4a5b;
  font-weight: bolder;
  text-decoration: none;
}

.nav-link-active {
  color: #3c4a5b;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #f2aa00;
  border-radius: 24px;
  padding: 2px 18px 4px;
}
